<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="primary">查詢</mdb-card-header>
            <mdb-card-body class="pt-1">
                <mdb-row>
                    <mdb-col md="3">
                        <datepicker label="調撥日期(起)"
                                    v-model="search.date_start"></datepicker>
                    </mdb-col>
                    <mdb-col md="3">
                        <datepicker label="調撥日期(迄)"
                                    v-model="search.date_end"></datepicker>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="search.page = 1;search_data()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <thead>
                        <tr>
                            <th style="width:4rem">#</th>
                            <th>轉出分店</th>
                            <th>轉入分店</th>
                            <th style="width:6rem">項目數量</th>
                            <th style="width:6rem">調撥人</th>
                            <th style="width:7rem">調撥日期</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in product_transferList"
                            :key="`p_${sk}`">
                            <td data-title="#">
                                {{ (sk+1)+(data.nowPage-1)*10 }}
                            </td>
                            <td data-title="轉出分店">
                                {{ storeKeyList[s.id_source_store] ? storeKeyList[s.id_source_store].name : '' }}
                            </td>
                            <td data-title="轉入分店">
                                {{ storeKeyList[s.id_target_store] ? storeKeyList[s.id_target_store].name : '' }}
                            </td>
                            <td data-title="項目數量">
                                {{ s.item_list.length }} 項
                            </td>
                            <td data-title="調撥人">
                                {{ adminKeyList[s.id_admin] ? adminKeyList[s.id_admin].name : '' }}
                            </td>
                            <td data-title="調撥日期">
                                {{ s.ctime.split(' ')[0] }}
                            </td>
                            <td>
                                <mdb-btn color="grey darken-1"
                                         size="sm"
                                         @click="$router.push(`/product/transfer/view/${s.id}`)">
                                    <i class="fa fa-eye mr-1"></i>
                                    檢視
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"
                                v-show="product_transferList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;search_data();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbBtn,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbBtn,
    datepicker,
    page,
  },
  data() {
    return {
      search: { 
        date_start: "", 
        date_end: "", 
        page: 1
      },
      search_change: false,
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      adminKeyList: {},
      storeKeyList: [],
      product_transferList: [],
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      switch (i) {
        default:
          vue.search[i] = vue.query[i] ? vue.query[i] : "";
          break;
      }
    }
    console.clear();
    vue.get_data();
  },
  watch: {
    search: {
      handler() {
        this.search_change = true;
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    // 搜尋資料
    search_data() {
      let vue = this;
      if (vue.search_change) {
        let url = vue.$route.path + "?";
        for (let k in vue.search) {
          url += `${k}=${vue.search[k]}&`;
        }
        vue.$router.push(url);
        vue.$nextTick(() => {
          vue.search_change = false;
        });
      }
      vue.get_data();
    },
    /// 
    get_data() {
      let vue = this;
      let query = [
        {
          name: "admin",
          data: {},
        },
        {
          name: "store",
          data: {
            active: {
              type: 0,
              value: 1,
            },
          },
          sort: {
            name: 0,
          },
        },
      ];
      // 搜尋資料
      let obj = {
        name: "product_transfer",
        data: {},
        sort: { id: 1 },
        pg: vue.search.page == "" ? 1 : vue.search.page,
      };
      if (vue.search.date_start != "" && vue.search.date_end != "") {
        obj.data.ctime = {
          type: 7,
          value: [
            vue.search.date_start + " 00:00:00", 
            vue.search.date_end + " 23:59:59", 
          ],
        };
      } else if (vue.search.date_start != "") {
        obj.data.ctime = { 
          type: 5, 
          value: vue.search.date_start 
        };
      } else if (vue.search.date_end != "") {
        obj.data.ctime = { 
          type: 4, 
          value: vue.search.date_end 
        };
      }
      query.push(obj);
      query.push(Object.assign({ num: 1 }, obj));
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${encodeURI(JSON.stringify(query))}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // 頁碼
            vue.data.totalPage = Math.ceil(res.data.product_transferNumber / 10);
            vue.data.nowPage = vue.search.page ? parseInt(vue.search.page) : 1;
            if (res.data.product_transferList) {
              vue.product_transferList = res.data.product_transferList.map((item) => {
                item.item_list = JSON.parse(item.item_list);
                return item;
              });
            }
            // 人員
            res.data.adminList.forEach((item) => {
              vue.adminKeyList[item.id] = item;
            });
            // 分店資料
            res.data.storeList.forEach((item) => {
              vue.storeKeyList[item.id] = item;
            });
          }
        });
    },
  },
};
</script>